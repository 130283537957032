@tailwind base;
@tailwind components;
@tailwind utilities;

.carouselImage {
  background-position: center;
  background-size: cover;
  aspect-ratio: 8/3;
}

@media only screen and (max-width: 768px) {
  .carouselImage {
    background-position: center;
    background-size: cover;
    aspect-ratio: 3/2;
  }
}

.picThumb {
  background-position: top;
  background-size: cover;
  aspect-ratio: 2/3;
}

.sigThumb {
  background-position: center;
  background-size: cover;
  aspect-ratio: 4/1;
}

.dark .PhoneInputInput {
  background-color: rgb(55 65 81);
  border: none;
}

.PhoneInputInput {
  background-color: rgb(249 250 251);
  border: none;
}

.dark .PhoneInputCountry select option {
  color: white !important;
}

.dark .PhoneInputCountrySelect {
  background-color: rgb(55 65 81);
  border: none;
}

.PhoneInputCountrySelect {
  background-color: rgb(249 250 251);
  border: none;
}

.ant-collapse-header {
  font-size: large !important;
}

.dark .ant-collapse-header {
  color: whitesmoke !important;
  font-size: large !important;
}

.ant-collapse-content-box {
  font-size: medium !important;
}

.dark .ant-collapse-content-box {
  color: rgb(156 163 175) !important;
  font-size: medium !important;
}

.dark option {
  color: black !important;
}

.ant-btn-primary {
  background-color: #3b82f6 !important;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 1.5rem;
  height: 1.25rem;
  right: 20px;
  top: 14px;
}

.hideButton {
  display: none !important;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: black;
}

.dark .bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: white;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  left: 20px;
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: black;
}

.dark .bm-cross {
  background: white;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: whitesmoke;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  width: screen/4;
}

.dark .bm-menu {
  background: rgb(17 24 39);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  width: screen/4;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  line-height: 3em;
  color: black;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.dark .bm-item {
  display: inline-block;
  line-height: 3em;
  color: #d1d1d1;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

/* .bm-item:hover {
  color: #ffffff;
} */

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.bm-burger-button {
  display: none;
}

@media screen and (max-width: 768px) {
  .bm-burger-button {
    display: block;
  }
}

.dark .ant-pagination-item-link {
  color: white !important;
}
